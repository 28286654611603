$(document).ready(function() {

    // 全局页面进入loading
    function pageLoading() {
        setTimeout(function() {
            var index = layer.load(2, { shade: [0.1, '#fff'] });
            setTimeout(function() {
                layer.close(index);
                $('.landing').css({ 'opacity': '1' });
            }, 500);
        }, 100);

    }
    pageLoading();

    $(window).scroll(function() {
        var scrollTop = $(this).scrollTop();
        var scrollHeight = $(document).height();
        var windowHeight = $(this).height();
        if ($(document).scrollTop() > 0) {
            $('.header').addClass('nav-ani');
        } else {
            $('.header').removeClass('nav-ani');
        }
    });

    $('.role-center .swiper-wrapper').on('click', '.btn', function(event) {
        var data = $(this).attr('data');
        $('.role-skill').fadeIn().find('.skill-box-0' + data + '').addClass('skill-show').siblings().removeClass('skill-show');
    });

    var swiper1 = new Swiper('.swiper-container-01', {
        pagination: '.swiper-pagination',
        paginationClickable: true,
        spaceBetween: 0,
    });

    var swiper2 = new Swiper('.swiper-container-02', {
        pagination: '.swiper-pagination',
        paginationClickable: true,
        spaceBetween: 30,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        effect: 'cube',
        grabCursor: true,
        cube: {
            shadow: true,
            slideShadows: true,
            shadowOffset: 20,
            shadowScale: 0.94
        }
    });

    var swiper3 = new Swiper('.swiper-container-03', {
        followFinger: false,
        speed: 500,
        onInit: function(swiper) {
            var slide = swiper.slides.eq(0);
            slide.addClass('ani-slide');
            swiper.slideTo(3, 1000, true);
            var sNum = $('.swiper-container-03 .swiper-slide').length;
            for (let i = 0; i < sNum; i++) {
                $('.role-0' + (i + 1)).on('click', function() {
                    swiper.slideTo(i, 1000, true);
                });
            }
        },
        onTransitionStart: function(swiper) {
            for (var i = 0; i < swiper.slides.length; i++) {
                var slide = swiper.slides.eq(i);
                slide.removeClass('ani-slide');
            }
        },
        onTransitionEnd: function(swiper) {
            var slide = swiper.slides.eq(swiper.activeIndex);
            slide.addClass('ani-slide');
        },
    });

    $('.floar-code').on('click', '.close-btn', function(event) {
        var _this = $(this).parents('.floar-code');
        if (_this.hasClass('hide')) {
            _this.removeClass('hide');
        } else {
            _this.addClass('hide');
        }
    });

    $('.tabs-box').on('click', '.item', function(event) {
        $(this).addClass('select').siblings().removeClass('select').parents('.tabs').siblings('.img-conter').children('ul').eq($(this).index()).addClass('show').siblings().removeClass('show');
    });

    $('.left-inf').on('click', 'li', function(event) {
        $(this).addClass('select').siblings().removeClass('select').parents('.left-inf').siblings('.right-inf').children('div').eq($(this).index()).addClass('show').siblings().removeClass('show');
    });

    $('.title').on('click', 'a', function(event) {
        $(this).addClass('select').siblings().removeClass('select').parents('.title').siblings('.information').children('.role-inf').eq($(this).index()).addClass('show').siblings().removeClass('show');
    });

    $('.img-all-main .img-conter').on('click', 'li', function(event) {
        var data = $(this).attr('data');
        console.log(data)
        $('.img-amplify-wrap').fadeIn().find('img').attr('src', $(this).find('img').attr('src'));
    });

    $('.img-amplify-wrap').on('click', '.close-btn', function(event) {
        $('.img-amplify-wrap').fadeOut();
    });


    $('.role-skill').on('click', '.close-btn', function(event) {
        $('.role-skill').fadeOut();
    });


    $('.return').click(function() {
        $('html, body').stop().animate({
            'scrollTop': '0'
        }, 1000);
    });

});

if (!navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i) && navigator.appName !== "Microsoft Internet Explorer") {
    var wow = new WOW({
        boxClass: 'wow',
        animateClass: 'animated',
        offset: 0,
        mobile: true,
        live: true
    });
    wow.init();


} else {
    var isLogin = false;
    var isWechat = window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i);
    var isMobile = navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i);

    // 隐藏导航
    var change = true;
    $('.header').on('click', '.nav_more', function() {
        if (change == true) {
            $('.nav_more').addClass('change');
            $('.right-nav').fadeIn();
            $('.right-nav').animate({ 'top': '2.48rem' });
            change = false;
        } else {
            $('.nav_more').removeClass('change');
            $('.right-nav').animate({ 'top': '-40rem' });
            $('.right-nav').fadeOut();
            change = true;
        }
    });
    // 横向
    var galleryTop = new Swiper('.gallery-top', {
        spaceBetween: 400,
        loop: true,
        loopedSlides: 5,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
    var galleryThumbs = new Swiper('.gallery-thumbs', {
        spaceBetween: 0,
        slidesPerView: 4.5,
        touchRatio: 2,
        centeredSlides: true,
        loop: true,
        loopedSlides: 5,
        slideToClickedSlide: true,
    });
    galleryTop.params.control = galleryThumbs;
    galleryThumbs.params.control = galleryTop;

    $('.code-ul').on('click', '.code', function(event) {
        var _thisSrc = $(this).find('img').attr('src');
        $('.code-wrap').fadeIn().find('img').attr('src', _thisSrc);
    });

    $('.bg').click(function(event) {
        $('.code-wrap').fadeOut();
    });

    $('.img-all-main .img-conter').on('click', 'li', function(event) {
        var data = $(this).attr('data');
        $('.img-wrap').fadeIn().find('img').attr('src', $(this).find('img').attr('src'));
    });

    $('.img-wrap').on('click', '.close', function(event) {
        $('.img-wrap').fadeOut();
    });

    $('.guanfang-wechat').click(function() {
        var $qcgameQRcode = $('.code-wrap');
        if (isWechat) {
            $qcgameQRcode.find('span').html('长按二维码关注公众号');
            $('.code-wrap').fadeIn();
        } else {
            layer.msg('请在微信内搜索“青瓷游戏”公众号', { time: 5000 });
        }
    });

    // public class PlayerActivity extends Activity { 
    //     protected void onCreate(Bundle savedInstanceState) { 
    //         //here 
    //         mPlayer.setWebViewClient(new WebViewClient() { 
    //             // autoplay when finished loading via javascript injection 
    //             public void onPageFinished(WebView view, String url) { mPlayer.loadUrl("javascript:(function() { var videos = document.getElementsByTagName('video'); for(var i=0;i<videos.length;i++){videos[i].play();}})()"); } 
    //         }); 
    //     } 
    // }
}